import { i18n } from "@/plugins/i18n";

export default {
  required: (val) => !!val || i18n.t("validations.required"),
  alpha: (val) => {
    const letters = /^[A-Za-z]+$/;
    if (val.match(letters)) {
      return true;
    } else {
      return i18n.t("validations.onlyAlphabeticChars");
    }
  },
  numeric: (val) => {
    const numbers = /^-?(0|[1-9]\d*)(\.\d+)?$/;
    if (!`${val}`?.match(numbers)) {
      return i18n.t("validations.onlyNumericChars");
    } else {
      return true;
    }
  },
  numericAndSymbols: (val) => {
    const symbols = /^[\d()+]+$/;
    if (val.match(symbols)) {
      return true;
    } else {
      return i18n.t("validations.onlyNumericAndSymbols");
    }
  },
  lengthOfNine: (val) => {
    return val.length === 9
      ? !!val
      : i18n.t("validations.exactChars", { count: 9 });
  },
  phoneNumber: (val) => {
    return val.length >= 10 && val.length <= 20
      ? !!val
      : i18n.t("validations.inRangeChars", { from: 10, to: 20 });
  },
  password: (val) => {
    if (val.length < 8) {
      return i18n.t("validations.password.tooShort");
    }
    return true;
  },
  email: (val) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(val) || i18n.t("validations.email");
  },
  maxChars: (val) => {
    return val.length >= 1 && val.length <= 140
      ? !!val
      : i18n.t("validations.inRangeChars", { from: 1, to: 140 });
  },
  siteUrl: (url) => {
    const pattern = /[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/;
    return pattern.test(url) ? !!url : i18n.t("validations.url");
  },
};
