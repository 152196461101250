<template>
  <v-form
    class="form"
    v-model="valid"
    ref="formResetPassword"
    @submit.prevent="resetPassword"
  >
    <div class="form__logo">
      <LogoBase />
    </div>
    <div class="form__title">
      {{ $t("authPages.loginPage.formTitle") }}
    </div>
    <div class="form__body">
      <v-text-field
        v-model="email"
        outlined
        label="Email"
        type="email"
        persistent-placeholder
        dense
        :placeholder="$t('placeholders.email')"
        :rules="[validations.email, validations.required]"
      ></v-text-field>
      <div class="link-block">
        <router-link :to="{ name: 'LoginPage' }">
          Retour
        </router-link>
      </div>

      <v-btn
        color="primary"
        depressed
        :loading="loading"
        :disabled="loading"
        type="submit"
        class="form__submit"
      >
        {{ $t("userActions.confirm") }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { UserService } from "@/services/user.service.js";
import validations from "@/validations/index";
export default {
  name: "ForgotPasswordForm",
  components: {
    LogoBase: () => import("~cp/Logo/LogoBase"),
  },
  data() {
    return {
      loading: false,
      email: "",
      valid: false,
    };
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    resetPassword() {
      this.$refs.formResetPassword.validate();
      if (!this.valid) return;
      this.loading = true;
      UserService.passwordRecovery(this.email)
        .then(() => {
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.passwordForgot")
          );
          this.$router.push({ name: "LoginPage" });
        })
        .catch(({response}) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", response);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style src="../Form.scss" lang="scss" scoped></style>
